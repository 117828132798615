import { getTextReadableTime } from '@/util/Util'

export default {

  methods: {
    correctQuestion(correctiton, question) {
      this.$vs.loading()
      this.questionnaireAnswerService
        .correct(question.answer_id, question.question_id, correctiton)
        .then(response => {
          const message = response.correction ? 'Resposta marcada como <b class="dimmer text-primary">correta<b>' : 'Resposta marcada como <b class="dimmer text-danger">incorreta</b>'
          this.notifySuccess(this.$vs, message, getTextReadableTime(message))
          this.$root.$emit('corrected', 
            question,  {
              correction: response.correction || correctiton,
              score: response.score
            }
          )
          this.key++
          this.$vs.loading.close()
        }, error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, 'Erro ao tentar marcar a correção dessa resposta.')
        })
    },
    disableWrongButton(data) {
      const questionId = this._.get(data, 'question_id')
      const invalidated = this.invalidatedQuestion(questionId)

      if (invalidated) {
        return true
      }

      if (data === null || data.correction === undefined) {
        return false
      } else if (!data.correction) {
        return true
      }
      return false
    },
    invalidatedQuestion(questionId) {
      const questions = this._.get(this, 'fields')
      if (this._.isArray(questions) && questions.length > 0 && questionId) {
        const question = questions.find((question) => question.id === questionId)
        const invalidationJustification = this._.get(question, 'data.invalidated.justification', false)
        return invalidationJustification !== false
      } else {
        return false
      }
    },
    disableCorrectedButton(data) {
      const questionId = this._.get(data, 'question_id')
      const invalidated = this.invalidatedQuestion(questionId)

      if (invalidated) {
        return true
      }
      
      if (data === null || data.correction === undefined) {
        return false
      } else if (data.correction) {
        return true
      }
      return false
    },
  },
}