<template>
  <div>
    <vx-card>
      <div class="grid grid-rows-1">
        <div class="grid grid-cols-2">
          <div class="float-left text-sm">
            <label class="font-bold">{{ $t('valor') }}:</label>
            <label>
              ({{ formatFloat(_.get(completeQuestion, 'data.score', 0.0), 2) }})
            </label>
          </div>
        </div>
      </div>
      <div>
        <multiple-choice-show
          :item="completeQuestion"
          v-if="completeQuestion && completeQuestion.type === 'multiple_choice'"
          :key="key"
        ></multiple-choice-show>

        <check-box-show
          :item="completeQuestion"
          v-if="completeQuestion && completeQuestion.type === 'check_box'"
          :key="key"
        ></check-box-show>

        <simple-text-show
          :item="completeQuestion"
          v-if="completeQuestion && completeQuestion.type === 'simple_text'"
          :key="key"
        ></simple-text-show>

        <gap-show
          :item="completeQuestion"
          v-if="completeQuestion && completeQuestion.type === 'gap'"
          :key="key"
        ></gap-show>

        <essay-show
          :item="completeQuestion"
          v-if="completeQuestion && completeQuestion.type === 'essay'"
          :key="key"
        ></essay-show>
      </div>
    </vx-card>

    <vs-divider/>

    <div class="flex flex-wrap float-right">
      <vs-button
        type="filled"
        icon="check"
        class="m-1"
        :disabled="disableCorrectedButton(completeQuestion)"
        @click="correctQuestion(true, completeQuestion)"
        v-tooltip.top="{
          content: $t('marcar-questao-como-correta'),
          delay: { show: 1000, hide: 100 }
        }"
      ></vs-button>
      <vs-button
        type="filled"
        color="danger"
        icon="clear"
        class="m-1"
        :disabled="disableWrongButton(completeQuestion)"
        @click="correctQuestion(false, completeQuestion)"
        v-tooltip.top="{
          content: $t('marcar-questao-como-incorreta'),
          delay: { show: 1000, hide: 100 }
        }"
      ></vs-button>
    </div>

  </div>
</template>

<script>
import QuestionCorrection from '@/mixins/QuestionCorrection'
import MultipleChoiceShow from '../../answers/questions/show/MultipleChoiceShow.vue'
import CheckBoxShow from '../../answers/questions/show/CheckBoxShow.vue'
import SimpleTextShow from '../../answers/questions/show/SimpleTextShow.vue'
import EssayShow from '../../answers/questions/show/EssayShow.vue'
import GapShow from '../../answers/questions/show/GapShow.vue'

export default {
  components: { MultipleChoiceShow, CheckBoxShow, SimpleTextShow, GapShow, EssayShow },
  data: () => ({
    key: 0
  }),
  props: {
    completeQuestion: {
      type: Object,
      default: null
    },
    questionnaireAnswerService: {
      type: Object,
      default: null
    }
  },
  watch: {
    completeQuestion() {
      this.key++
    }
  },
  mixins: [QuestionCorrection]
}
</script>

<style>

</style>
