import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import QuestionAnswer from '@/views/apps/questionnaires/elements/QuestionAnswer.vue'
import { getQuestion, getQuestionType } from '@/util/QuestionnaireUtil'
import i18n from '@/i18n/i18n'

export default {
  components: {
    QuestionAnswer
  },
  props: {
    model: {
      type: Object,
      default: null
    },
    fields: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
  },

  data: () => ({
    questionnaireAnswerService: null,
    key: 0,
    comments: {
      readCache: []
    },
  }),

  methods: {
    getCompleteQuestion(dataAnswer) {
      const question = this.fields.find(element => {
        return element.id === dataAnswer.question_id
      })
      if (question) {
        return {
          ...question,
          ...dataAnswer,
          score: this.formatFloat(question.data.score),
          answer_score: dataAnswer.score
        }
      }
    },
    firstFinishedAt(answer) {
      let dataEntrega = ''
      if(answer.length > 0){
        if(answer[0].finished_at){
          dataEntrega = i18n.t('data-entrega-this-moment-answer-0-finish', [this.$moment(answer[0].finished_at).format('DD/MM/YYYY kk:mm')]);
        }else{
          dataEntrega = i18n.t('data-entrega-this-moment-answer-not-finish');
        }
      }

      return dataEntrega
    },
    rewindedAt(answer) {
      let rewindedAtFmt = null
      if(answer.length > 0 && answer[0].rewinded_at){
        rewindedAtFmt = i18n.t('segunda-chance-em-0', [this.$moment(answer[0].rewinded_at).format('DD/MM/YYYY kk:mm')]);
      }
      return rewindedAtFmt
    },
    resetAnswer(answerId, user) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: i18n.t('segunda-chance'),
        acceptText: i18n.t('sim'),
        cancelText: i18n.t('nao'),
        text: i18n.t('tem-certeza-que-quer-abrir-a-tentativa', [user.user_name]),
        accept: () => {
          this.$vs.loading()
          this.questionnaireAnswerService.reset(answerId).then(
            response => {
              this.$vs.loading.close()
              this.notifySuccess(this.$vs, i18n.t('operacao-realizada-com-sucesso'))
              this.$emit('secondChance')
            },
            error => {
              this.notifyError(this.$vs, 'ERRO')
              this.$vs.loading.close()
            }
          )
        }
      })

    },
    getScoreTotalAnswer(answer) {
      let maxTotalScore = this.getScoreTotal(answer)
      let score = this.getScore(answer)
      if (!score && score !== 0) { score = ' - ' }
      if (maxTotalScore === 0) { maxTotalScore = ' - ' }
      return i18n.t('pontuacao-score-maxtotalscore', [score, maxTotalScore])
    },
    correctionStatus(data) {
      return data.correction
        ? 'success'
        : data.correction === false
          ? 'danger'
          : null
    },
    getRowClass(data, type) {
      const base = ''
      const questionId = this._.get(data, 'question_id')

      if (questionId) {
        const question = getQuestion(questionId, this.fields, {})
        const isInvalidated = this._.get(question, 'data.invalidated.justification', false)
        if (isInvalidated !== false) {
          return `${base} invalidated-item pointer-events-auto without-bg`
        }
      }

      return base
    },
    shortTitle(title) {
      return this.truncate(this._.trim(this.html2text(title)), 50)
    },
    scoreChanged(question, maxScore) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      const score = Number.parseFloat(question.score)
      const maxScoreFloat = Number.parseFloat(maxScore)
      if (score < 0) {
        question.score = 0
      } else if (score > maxScoreFloat) {
        question.score = maxScoreFloat
        this.notifyWarning(this.$vs, i18n.t('o-valor-da-pontuacao-nao-pode-ultrapassar', [maxScoreFloat]), 3000)
      }
      //this.$vs.loading()
      this.timeout = setTimeout(() => {
        this.questionnaireAnswerService
          .score(question.answer_id, question.question_id, question.score)
          .then(response => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, i18n.t('operacao-realizada-com-sucesso'))
            //this.updateQuestion(question, {
            //  score: parseFloat(response.score)
            //})
            this.$root.$emit('corrected',
              question,  {
                correction: response.correction,
                score: response.score
              }
            )

            this.key++
          },
          error => {
            this.notifyError(this.$vs, 'Erro ao tentar alterar a nota dessa resposta.')
            this.$vs.loading.close()
          }).finally(
            () => {
              this.$vs.loading.close()
            }
          )
      }, 1000)
    },
    getMaxScore(answer) {
      const questionId = answer.question_id
      const question = this.fields.find((field) => {
        return field.id === questionId
      })
      return question && question.data && question.data.score
    },
    showCompleteQuestionModal(dataAnswer) {
      this.$emit('showCompleteQuestionModal', this.getCompleteQuestion(dataAnswer))
    },
    showCommentSectionModal(answerData) {
      this.$emit('showCommentModal', answerData, this)
    },
    unreadComments(count, question_id) {
      if (this.comments.readCache.indexOf(question_id) === -1) {
        return count
      } else {
        return 0
      }
    },
    getScoreTotal(answers) {
      let score = 0

      if (answers !== null && answers.length > 0) {
        for (let index = 0; index < answers.length; index++) {
          score += (!this.isEmpty(answers[index].maxScore)) ? Number.parseFloat(answers[index].maxScore) : 0
        }
      }

      return score
    },
    getScore(answers) {
      let score = 0
      if (answers !== null && answers.length > 0) {
        for (let index = 0; index < answers.length; index++) {
          score += (!this.isEmpty(answers[index].score)) ? Number.parseFloat(answers[index].score) : 0
        }
      }
      return score
    },
  }

}
