<template>
  <div>
    <div v-if="component !== null">
      <component :item="data" :is="component" :brief-mode="true"/>
    </div>
    <div v-else v-html-safe="buildAnswerHtml(data, i)"/>
  </div>
</template>

<script>
import SimpleTextShow from '@/views/apps/questionnaires/answers/questions/show/SimpleTextShow'
import MultipleChoiceShow from '@/views/apps/questionnaires/answers/questions/show/MultipleChoiceShow'
import CheckBoxShow from '@/views/apps/questionnaires/answers/questions/show/CheckBoxShow'
import GapShow from '@/views/apps/questionnaires/answers/questions/show/GapShow'

export default {

  components: {
    SimpleTextShow,
    MultipleChoiceShow,
    CheckBoxShow,
    GapShow,
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
  },

  data: () => ({

  }),

  computed: {
    component() {
      const type = _.get(this.data, 'type')

      switch (type) {
      case 'gap': return 'GapShow'
    
      default: return null
      }
    }
  },

  methods: {
    buildAnswerHtml(data) {

      const answer = _.get(data, 'answer', this.$t('nao-respondido')) || this.$t('nao-respondido')
      const questionId = _.get(data, 'question_id', null)

      if (typeof answer === 'string') {
        return `<span>${this.truncate(this.html2text(answer), 50)}</span>`
      } else if (typeof answer === 'object' && ('image' in answer || 'label' in answer)) {
        let labelHasHtml = false
        if ('label' in answer) {
          labelHasHtml = /<\/?[a-z][\s\S]*>/i.test(answer.label)
        }
        return this.renderImgAnswerElement(answer, 0, labelHasHtml)
      } else if (typeof answer === 'object' && Array.isArray(answer)) {
        return this.renderCheckBoxAnswerWithImgs(answer)
      }
      return `<span>${this.truncate(this.html2text(answer), 50)}</span>`
    },
    renderCheckBoxAnswerWithImgs(answerData) {
      const optionContainers = []
      let answerContainer = '<div class="flex content-center">'
      answerData.forEach((answer, i) => {
        answerContainer += this.renderImgAnswerElement(answer, i)
      })
      answerContainer += '</div>'
      return answerContainer
    },
    renderImgAnswerElement(answerObj, position = 0, labelHasHtml = false) {
      const labelContent = labelHasHtml ?
        `<div class="mb-auto pt-1 overflow-y-scroll" style="width: 100px;">${answerObj.label}</div>` :
        `<span class="text-center">${this.truncate(answerObj.label, 12)}</span>`
      return `<div
         class="flex content-between flex-col ${ (position > 0) ? 'ml-2 pl-2 border-l border-r-0 border-t-0 border-b-0 border-faint-grey border-solid ' : ''}"
         style="max-height: 200px;max-width:100px;"
        >
          ${'image' in answerObj ? `<img class="mb-auto" style="width: 100px;" src="${answerObj.image.src}"/>` : ''}
          ${labelContent}
        </div>`
    },
  }
}
</script>

<style>

</style>