var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.model
        ? _c("vx-card", { key: _vm.key }, [
            _c(
              "div",
              {
                staticClass: "w-full",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_vm._t("header")],
              2
            ),
            _c(
              "p",
              { staticClass: "mb-3" },
              _vm._l(_vm.model.answers, function (answer, i_Answer) {
                return _c(
                  "vx-card",
                  {
                    key: answer,
                    staticClass: "mb-4",
                    attrs: {
                      title: _vm.firstFinishedAt(answer),
                      "collapse-action": "",
                      rewindContentAction: "",
                      headerBackground: answer[0].finished_at ? "" : "#F5EAD9",
                      rewindTooltip: "Segunda chance",
                    },
                    on: {
                      rewind: function ($event) {
                        _vm.resetAnswer(
                          _vm.model.answers[i_Answer][0].answer_id,
                          _vm.model
                        )
                      },
                    },
                  },
                  [
                    _vm.rewindedAt(_vm.model.answers[0])
                      ? _c(
                          "p",
                          { staticClass: "pb-3 text-primary font-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.rewindedAt(_vm.model.answers[0])) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "mb-3" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getScoreTotalAnswer(_vm.model.answers[0])
                            ) +
                            " | " +
                            _vm._s(_vm.$t("pontuacao-nivelada")) +
                            ": " +
                            _vm._s(
                              _vm.model.answers[i_Answer][0]
                                ? _vm.model.answers[i_Answer][0].leveled_score
                                : ""
                            ) +
                            " "
                        ),
                        _c(
                          "vs-table",
                          {
                            attrs: {
                              noDataText: "",
                              data: _vm.model.answers[i_Answer],
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var data = ref.data
                                    return _vm._l(data, function (tr, i) {
                                      return _c(
                                        "vs-tr",
                                        {
                                          key: i,
                                          class: _vm.getRowClass(
                                            data[i],
                                            "user"
                                          ),
                                          attrs: {
                                            state: _vm.correctionStatus(
                                              data[i]
                                            ),
                                          },
                                        },
                                        [
                                          _c("vs-td", [
                                            _vm._v(" " + _vm._s(i + 1) + " "),
                                          ]),
                                          _c(
                                            "vs-td",
                                            {
                                              attrs: {
                                                data: data[i].user_name,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.shortTitle(
                                                      data[i].title
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "vs-td",
                                            {
                                              staticClass: "answer",
                                              attrs: { data: data[i].answer },
                                            },
                                            [
                                              _c("question-answer", {
                                                attrs: {
                                                  data: _vm.getCompleteQuestion(
                                                    data[i]
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vs-td",
                                            {
                                              staticClass: "w-score",
                                              attrs: { data: data[i].score },
                                            },
                                            [
                                              _c("vs-input", {
                                                attrs: {
                                                  lang: "pt-BR",
                                                  type: "number",
                                                  disabled:
                                                    _vm.invalidatedQuestion(
                                                      data[i].question_id
                                                    ),
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.scoreChanged(
                                                      data[i],
                                                      _vm.getMaxScore(tr)
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: data[i].score,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      data[i],
                                                      "score",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "data[i].score",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vs-td",
                                            { attrs: { data: data[i].id } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "grid justify-end grid-rows-2 __grid-columns-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "m-1 justify-self-end self-center",
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip.top",
                                                            value: {
                                                              content:
                                                                "Marcar questão como correta",
                                                              delay: {
                                                                show: 1000,
                                                                hide: 100,
                                                              },
                                                            },
                                                            expression:
                                                              "{\n                          content: 'Marcar questão como correta',\n                          delay: { show: 1000, hide: 100 }\n                        }",
                                                            modifiers: {
                                                              top: true,
                                                            },
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "filled",
                                                          icon: "check",
                                                          disabled:
                                                            _vm.disableCorrectedButton(
                                                              data[i]
                                                            ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.correctQuestion(
                                                              true,
                                                              data[i]
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "justify-self-start self-center m-1",
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip.top",
                                                            value: {
                                                              content:
                                                                "Marcar questão como incorreta",
                                                              delay: {
                                                                show: 1000,
                                                                hide: 100,
                                                              },
                                                            },
                                                            expression:
                                                              "{\n                          content: 'Marcar questão como incorreta',\n                          delay: { show: 1000, hide: 100 }\n                        }",
                                                            modifiers: {
                                                              top: true,
                                                            },
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "filled",
                                                          color: "danger",
                                                          icon: "clear",
                                                          disabled:
                                                            _vm.disableWrongButton(
                                                              data[i]
                                                            ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.correctQuestion(
                                                              false,
                                                              data[i]
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "justify-self-end self-center p-1",
                                                    },
                                                    [
                                                      _c("vs-button", {
                                                        directives: [
                                                          {
                                                            name: "tooltip",
                                                            rawName:
                                                              "v-tooltip.top",
                                                            value: {
                                                              content:
                                                                "Visualizar questão completa",
                                                              delay: {
                                                                show: 1000,
                                                                hide: 100,
                                                              },
                                                            },
                                                            expression:
                                                              "{\n                          content: 'Visualizar questão completa',\n                          delay: { show: 1000, hide: 100 }\n                        }",
                                                            modifiers: {
                                                              top: true,
                                                            },
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "filled",
                                                          color: "warning",
                                                          icon: "visibility",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showCompleteQuestionModal(
                                                              data[i]
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.showCommentButton
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "justify-self-start p-1 self-center",
                                                        },
                                                        [
                                                          _vm.unreadComments(
                                                            data[i].comments,
                                                            data[i].question_id
                                                          ) !== 0
                                                            ? _c(
                                                                "feather-icon",
                                                                {
                                                                  staticClass:
                                                                    "float-right h-auto z-50 mr-3 mt-2",
                                                                  attrs: {
                                                                    badge:
                                                                      _vm.unreadComments(
                                                                        data[i]
                                                                          .comments,
                                                                        data[i]
                                                                          .question_id
                                                                      ),
                                                                    svgClasses:
                                                                      "text-success",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showCommentSectionModal(
                                                                          _vm.field
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c("vs-button", {
                                                            directives: [
                                                              {
                                                                name: "tooltip",
                                                                rawName:
                                                                  "v-tooltip.top",
                                                                value: {
                                                                  content:
                                                                    "Abrir comentários",
                                                                  delay: {
                                                                    show: 1000,
                                                                    hide: 100,
                                                                  },
                                                                },
                                                                expression:
                                                                  "{\n                          content: 'Abrir comentários',\n                          delay: { show: 1000, hide: 100 }\n                        }",
                                                                modifiers: {
                                                                  top: true,
                                                                },
                                                              },
                                                            ],
                                                            attrs: {
                                                              type: "filled",
                                                              color: "success",
                                                              icon: "speaker_notes",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showCommentSectionModal(
                                                                  data[i]
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    })
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [_vm._v(_vm._s(_vm.$t("numero")))]),
                                _c("vs-th", [
                                  _vm._v(_vm._s(_vm.$t("questao"))),
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(_vm.$t("data_viewer.keys.answer"))
                                  ),
                                ]),
                                _c("vs-th", [_vm._v(_vm._s(_vm.$t("points")))]),
                                _c("vs-th", [
                                  _vm._v(_vm._s(_vm.$t("Actions"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }