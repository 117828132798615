<template>
  <div>
    <vx-card
      v-if="model"
      :key="key"
    >
      <div slot="header" class="w-full">
        <slot name="header"></slot>
      </div>
      <p class="mb-3">
        <vx-card
          :title="firstFinishedAt(answer)"
          v-for="(answer, i_Answer) of model.answers"
          :key="answer"
          class="mb-4"
          collapse-action
          rewindContentAction
          :headerBackground="answer[0].finished_at ? '' : '#F5EAD9'"
          :rewindTooltip="'Segunda chance'"
          @rewind="resetAnswer( (model.answers[i_Answer][0]).answer_id, model)">
          <p class="pb-3 text-primary font-bold" v-if="rewindedAt(model.answers[0])">
              {{rewindedAt(model.answers[0])}}
          </p>
          <p class="mb-3">
            {{getScoreTotalAnswer(model.answers[0])}} |
            {{ $t('pontuacao-nivelada') }}: {{ model.answers[i_Answer][0] ? model.answers[i_Answer][0].leveled_score : '' }}
            <vs-table noDataText="" :data="model.answers[i_Answer]">
              <template slot="thead">
                <vs-th>{{ $t('numero') }}</vs-th>
                <vs-th>{{ $t('questao') }}</vs-th>
                <vs-th>{{ $t('data_viewer.keys.answer') }}</vs-th>
                <vs-th>{{ $t('points') }}</vs-th>
                <vs-th>{{ $t('Actions') }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :state="correctionStatus(data[i])"
                  :key="i"
                  v-for="(tr, i) in data"
                  :class="getRowClass(data[i], 'user')"
                >
                  <vs-td>
                    {{ i + 1 }}
                  </vs-td>
                  <vs-td :data="data[i].user_name">
                    {{ shortTitle(data[i].title) }}
                  </vs-td>
                  <vs-td class="answer" :data="data[i].answer">
                    <question-answer :data="getCompleteQuestion(data[i])"/>
                  </vs-td>
                  <vs-td :data="data[i].score" class="w-score">
                    <vs-input
                      lang="pt-BR"
                      type="number"
                      v-model="data[i].score"
                      :disabled="invalidatedQuestion(data[i].question_id)"
                      @input="scoreChanged(data[i], getMaxScore(tr))"
                    />
                  </vs-td>
                  <vs-td :data="data[i].id">
                    <div class="grid justify-end grid-rows-2 __grid-columns-2">
                      <div class="m-1 justify-self-end self-center">
                        <vs-button
                          type="filled"
                          icon="check"
                          :disabled="disableCorrectedButton(data[i])"
                          @click="correctQuestion(true, data[i])"
                          v-tooltip.top="{
                            content: 'Marcar questão como correta',
                            delay: { show: 1000, hide: 100 }
                          }"
                        ></vs-button>
                      </div>
                      <div class="justify-self-start self-center m-1">
                        <vs-button
                          type="filled"
                          color="danger"
                          icon="clear"

                          :disabled="disableWrongButton(data[i])"
                          @click="correctQuestion(false, data[i])"
                          v-tooltip.top="{
                            content: 'Marcar questão como incorreta',
                            delay: { show: 1000, hide: 100 }
                          }"
                        ></vs-button>
                      </div>
                      <div class="justify-self-end self-center p-1" >
                        <vs-button
                          type="filled"
                          color="warning"
                          icon="visibility"
                          @click="showCompleteQuestionModal(data[i])"
                          v-tooltip.top="{
                            content: 'Visualizar questão completa',
                            delay: { show: 1000, hide: 100 }
                          }"
                        ></vs-button>
                      </div>
                      <div class="justify-self-start p-1 self-center" v-if="showCommentButton">
                        <feather-icon
                          v-if="unreadComments(data[i].comments, data[i].question_id) !== 0"
                          @click="showCommentSectionModal(field)"
                          :badge="unreadComments(data[i].comments, data[i].question_id)"
                          class="float-right h-auto z-50 mr-3 mt-2"
                          svgClasses="text-success"
                        >
                        </feather-icon>
                        <vs-button
                          type="filled"
                          color="success"
                          icon="speaker_notes"
                          @click="showCommentSectionModal(data[i])"
                          v-tooltip.top="{
                            content: 'Abrir comentários',
                            delay: { show: 1000, hide: 100 }
                          }"
                        ></vs-button>
                      </div>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </p>
        </vx-card>
      </p>
    </vx-card>
  </div>
</template>

<script>
import CorrectionPage from '@/mixins/CorrectionPage'
import QuestionCorrection from '@/mixins/QuestionCorrection'

export default {
  mixins: [CorrectionPage, QuestionCorrection],
  props: {
    showCommentButton: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">

.__grid-columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.__background-color-warning{
  background: rgba(231, 154, 23, 0.15)
}

</style>
